import { catalogRoutePrepareForServer } from "~/common/helpers.js";

let filtersList;
let filtersFromMainPage = {};

export default async function ({ route, error, store, $axios, redirect }) {
  const splited = route.path.split("/");
  if (route.name === "catalog-third-level" && splited.pop() === "couples") {
    redirect(
      process.env.BASE_URL_EMIRATE +
        splited.join("/") +
        "/for-couples-services-escorts"
    );
    return;
  }

  const isForwardedFromMain = store.getters["profiles/getIsForwardedFromMain"];
  if (!isForwardedFromMain) {
    store.commit("profiles/resetFilters");
  }

  store.commit("profiles/setDecodedRouteParams", null);

  const routeReadyToServer = catalogRoutePrepareForServer(route.path);
  filtersList = store.getters["profiles/getFiltersList"];
  filtersFromMainPage = store.getters["profiles/getFilters"];
  if (!isForwardedFromMain) {
    store.commit("profiles/setFilters", route.query);
  }

  try {
    store.commit("profiles/setIsProfilesAllShortLoading", true);
    store.commit("profiles/setNextPage", route.query.page || 1);

    store.commit("global/setSeoTexts", {
      title: "",
      description: "",
      h1: "",
      footer: "",
    });

    const response = await $axios.get("/api/models/get-catalog-page", {
      params: { route: routeReadyToServer, ...filtersFromMainPage },
    });

    if (route.query.page > response.data?.models_short?.last_page) {
      error({ statusCode: 404, message: "Page not found" });
    }

    if (response?.data?.models_short) {
      const getModelsShortResponse = response.data?.models_short;
      store.dispatch(
        "profiles/processProfilesShortResponse",
        getModelsShortResponse
      );
    }

    if (
      response.data?.day_models_short?.data &&
      response.data?.day_models_short?.data.length
    ) {
      store.commit(
        "profiles/setProfilesDay",
        response.data.day_models_short.data
      );
    }

    if (response.data?.filters_top) {
      store.commit("menus/setNumberModelsFilter", response.data.filters_top);
    }

    if (response?.data?.get_full_data) {
      store.commit("profiles/setFiltersList", response.data.get_full_data);
      filtersList = response.data.get_full_data;
    }

    if (response?.data?.seo_text) {
      store.commit("global/setSeoTexts", {
        title: response.data.seo_text.title,
        description: response.data.seo_text.description,
        h1: response.data.seo_text.h1,
        footer: response.data.seo_text.text,
      });

      store.commit("global/setSeoSettings", {
        isShowMoreEnabled: response.data.seo_text.enable_show_more == 1,
        page: response.data.seo_text.page,
        shortHeight: response.data.seo_text.shortHeight,
        shortLineCount: response.data.seo_text.shortLineCount,
      });
    }

    if (response?.data?.reviews && response?.data?.reviews.length) {
      store.commit("sliders/setLastReviews", response.data.reviews);
    } else {
      store.commit("sliders/setLastReviews", []);
    }

    if (response?.data?.viewed && response?.data?.viewed.length) {
      store.commit("profiles/setViewedProfiles", response.data.viewed);
    } else {
      store.commit("profiles/setViewedProfiles", []);
    }

    store.commit("category/setCatalogPageRequestData", {
      category: response.data?.category_group || "",
      subcategory: response.data?.page_name || "",
    });
  } catch (e) {
    console.log("---------------- CATALOG ROUTES ----------------");
    console.log(e);
    console.log("------------------------------------------------");
    error({ statusCode: 404, message: "Page not found" });
    store.commit("profiles/setProfilesAllShort", []);
    store.commit("profiles/setProfilesDay", []);
    store.commit("global/setSeoTexts", {
      title: "",
      description: "",
      h1: "",
      footer: "",
    });
  } finally {
    store.commit("profiles/setIsProfilesAllShortLoading", false);
    store.commit("profiles/setIsForwardedFromMain", false);
  }

  const decodedRouteParams = store.getters["profiles/getDecodedRouteParams"];
  if (decodedRouteParams) {
    decodedRouteParams.forEach((param, index) => {
      processParam(param, store);
      if (index === decodedRouteParams.length - 1) {
        store.dispatch("profiles/setDefaultFilters");
      }
    });
  }
}

function processParam(param, store) {
  switch (param.type) {
    case "location":
      processLocationParam(param, store);
      break;
    case "gender":
      processGenderParam(param, store);
      break;
    case "category":
      processCategoryParam(param, store);
      break;
    case "service":
      processServiceParam(param, store);
      break;
    case "nationality":
      processNationalityParam(param, store);
      break;
  }
}

function processLocationParam(param, store) {
  const newFilters = {};

  param.filter_type.forEach((filter, index) => {
    newFilters[filter] = param.filter_value[index];
    store.commit("profiles/setFilters", newFilters);

    if (filter === "city") {
      const city = filtersList.cities.find(
        (city) => city.id === param.filter_value[index]
      );
      store.commit("global/setCurrentCity", { id: city.id, name: city.title });
    }
    if (filter === "area") {
      const area = filtersList.districts.find(
        (area) => area.id === param.filter_value[index]
      );
      store.commit("global/setCurrentDistrict", area.title);
    }
  });
}

function processGenderParam(param, store) {
  store.commit("profiles/setFilters", { gender: param.filter_value[0] });
}

function processCategoryParam(param, store) {
  const newFilters = {};
  if (!param.filter_type) return;
  newFilters[param.filter_type[0]] = processCategoryValue(
    param.filter_value[0]
  );
  store.commit("profiles/setFilters", newFilters);
}

function processServiceParam(param, store) {
  const newFilters = {};
  newFilters[param.filter_type[0]] = param.filter_value[0].toString();
  store.commit("profiles/setFilters", newFilters);
}

function processNationalityParam(param, store) {
  const newFilters = {};
  newFilters["nationalities"] = param.filter_value[0].toString();
  store.commit("profiles/setFilters", newFilters);
}

function processCategoryValue(value) {
  if (value === "true") return true;
  else if (value === "false") return false;
  else return value;
}
